@import url("typography.css");
.App {
  font-family: Poppins, "sans-serif";
}
.background-container {
  position: relative;
  width: 100%;
  background-image: url("./assets/images/bg.jpg");
  background-size: cover;
  background-position: center;
}
.background-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 53, 87, 0.7);

  z-index: 1;
}
.background-container .content {
  position: relative;
  z-index: 2;
}
.noScroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.banner {
  background: url("./assets/images/overlay.png"), #1d3557; /* Path to your PNG image */
  background-size: cover; /* Adjust the size of the background image */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
}

.otp-code__input {
  border-radius: 0.5rem;
  font-family: monospace;
  height: 50px;
  border: 1px solid #ccc;
  font-size: 2rem;
  text-align: center;
  transition: all 0.2s ease;
  color: #222;
  width: 3rem !important;
  margin: 0 0.5rem;
}
.countdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.timer-text {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.resend-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff; /* Primary color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.resend-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #1b3c8e;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #1b3c8e;
  border-radius: 10px;
}

.noScroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
