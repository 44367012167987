@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/Poppins-ExtraBold.ttf");
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/Poppins-Italic.ttf");
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/Poppins-Light.ttf");
}
